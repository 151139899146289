<template>
    
    <CierreConcienciaComponente></CierreConcienciaComponente>
    
    </template>
    
    <script>
    
    import CierreConcienciaComponente from '@/components/conciencia/CierreConcienciaComponente.vue';
        export default {
    
            name : 'NuevoConciencia',
    
            components :{ CierreConcienciaComponente },
    
    
            data() {
                return {
                    parametro : '',
                   // AgregarConcienciaComponente
                }
            }
            
        }
    </script>
    
    <style scoped>
    
    </style>