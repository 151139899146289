<template>
    <v-row>

         <v-col cols="12" xs="12" md="6">   

            <v-text-field label="NOMBRE"  filled >

            </v-text-field>

         </v-col>
         <v-col cols="12" xs="12" md="6">   

            <v-text-field label="CARGO"  filled >

            </v-text-field>

         </v-col>


    </v-row>

</template>
<script>
export default {
  name: "textfieldElaboro",

  props: ["quienelaboro"],

  data() {
    return {
      elaboro: this.quienelaboro,
      showAyuda : false
    };
  },
  methods : {

    asignarValor(valor){
      //console.log("valor de elaboro en componente" + valor);
      this.$store.dispatch('setear_Elaboro',valor);
     
      valor.length > 0 ?
      this.$store.dispatch('actions_uivars_error_textDenunciante',false): 
      this.$store.dispatch('actions_uivars_error_textDenunciante',true)
      
      //console.log("valor de elaboro en state" + this.$store.state.incidentes.etapainicial_elaboro);
    }

  }
};
</script>